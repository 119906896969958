<template>
  <div class="net">
    <div class="navTitle"><img src="../assets/img/arrl.png" alt="" @click="goBack">外部链接</div>
    <div class="netword">
      <!-- {{url}} -->
      <iframe :src="url" width="100%" height="100%" frameborder="0" scrolling="auto"></iframe>
    </div>
  </div>
</template>
<script>
import { ref } from 'vue';
import {useRoute} from 'vue-router'
export default {
   setup() {
     let route = useRoute();
     const url = route.params.url;

    return { 
      url
    };
  },
  methods:{
    goBack(){
        this.$router.go(-1);
    },
  }
}
</script>
<style lang="less" scoped>
.net {
  padding-top: 12.5vw;
  .netword {
    width: 100vw;
    height: calc(100vh - 12.5vw);
  }
}
</style>
